
import { Container } from './styles';
import TableClients from '../../components/Table/TableClients';

const Clients = () => {


    return(
        <Container>
            <TableClients />
        </Container>
    )
}

export default Clients;