import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import api from '../../services/api';
import { withStyles, makeStyles, useTheme  } from '@material-ui/core/styles';
import { Container, Column } from './styles'
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'flu_nome', numeric: false, disablePadding: false, label: 'NOME', minWidth: 150, headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'flu_email', numeric: false, disablePadding: false, label: 'EMAIL', minWidth: 150,  headerClassName: 'super-app-theme--header', headerAlign: 'center'  },  
  { id: 'flu_empresa', numeric: false, sortable: true, disablePadding: false, label: 'EMPRESA', minWidth: 250,  headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'flu_nivel', numeric: false, disablePadding: false, label: 'NÍVEL', minWidth: 100,  headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'flu_status', numeric: false, disablePadding: false, label: 'STATUS', minWidth: 100,  headerClassName: 'super-app-theme--header', headerAlign: 'center'},   
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>      
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.headerAlign}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const StyledTableCell = withStyles(() => ({
  root: {
      borderBottom: "none"
    },
  head: {
      backgroundColor: "#495057",
      color: "#fff",
      fontSize: 12,
      fontWeight: 'bold',
  },
  body: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#495057'
  }
}))(TableCell);


function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export default function TableClients () {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [dataClients, setDataClients] = useState([]);
  
    useEffect(() => {
        const getUsers = async () => {
            await api.get('/api/getClients')
                .then(res => {         
                    setDataClients(res.data.result);
            });   
        }
        getUsers();
    }, [])

    const handleChangePage = (event, newPage) => {  
      setPage(newPage);  
    };  
  
    const handleChangeRowsPerPage = event => {  
      setRowsPerPage(+event.target.value);  
      setPage(0);  
    }; 

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = dataClients.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, dataClients.length - page * rowsPerPage);
    return (            
        <Container>
             
             <Column >
             <Paper className={classes.paper}>                              
              <TableContainer>
                  <Table   className={classes.table}
                  aria-labelledby="tableTitle"
                  size='small'
                  aria-label="enhanced table">
                  <EnhancedTableHead
                    classes={classes}                    
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={dataClients.length}
                   />
                    <TableBody>
                    {stableSort(dataClients, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {                        
                        return(
                          <TableRow
                          hover
                          key={row.flu_id}
                          >
                             
                            <TableCell style={{fontSize: '.7rem'}} align="center"> {row.flu_nome}</TableCell>                            
                            <TableCell style={{fontSize: '.7rem'}} align="center">{row.flu_email}</TableCell>                           
                            <TableCell style={{fontSize: '.7rem'}} align="center">{row.flu_empresa}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}} align="center">{row.flu_nivel === 1 ? "Administrador" : "Cliente"}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}} align="center">{row.flu_status === 1 ? "Ativo" : "Inativo"}</TableCell>
                          </TableRow>
                        );
                      })
                      }
                       {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
                  </TableBody>
                </Table>
                </TableContainer>
                <TablePagination  
                 rowsPerPageOptions={[10, 20, 30]}  
                 component="div"  
                 count={dataClients.length}  
                 rowsPerPage={rowsPerPage}  
                 page={page} 
                 labelRowsPerPage={"REGISTROS POR PÁGINA"}
                 classes={{
                     root: classes.tablePagination,
                     caption: classes.tablePaginationCaption,
                     selectIcon: classes.tablePaginationSelectIcon,
                     select: classes.tablePaginationSelect,
                     actions: classes.tablePaginationActions,
                   }}
                 onChangePage={handleChangePage}  
                 onChangeRowsPerPage={handleChangeRowsPerPage}  
            />              
            </Paper>   
            
              </Column>                         
      </Container>
    );        
}

