import styled from 'styled-components';

export const Container = styled.div`
    /* max-width: 1360px; */
    
    /* margin-right: auto;
    margin-left: auto; */
    box-sizing: border-box;
    /* display: flex; */
    &:before,
    &:after{
        content: " "            ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;