import styled from "styled-components";

export const Container = styled.div`
    /* margin: 0 auto; */
    /* width: 84rem; */

    height: 10rem;
    padding-right: 2rem;
    padding-left:2rem;
    box-sizing: border-box;
    &:before,
    &:after{
        content: " "            ;
        
    }
    &:after{
        clear: both;
    }
`;

export const Label = styled.span`
    font-family: 'Poppins', sans-serif;
    font-size: .9rem;
    font-weight: bold;
    display: block;

`;

export const ExportCsv = styled.div`
    font-family: 'Poppins', sans-serif;
    margin-left: 1.5rem;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: .6rem;
    color: #666868;
    opacity: .7;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 4rem;
    border-radius: 5px;
    height: 1.2rem;
    border: none;
    outline: none;
    transition: 0.15s;
    text-align: center;
`

function getWidthGrid(value){
    if (!value) return;

    let width = value / 12 * 100;
    return `width ${width}%;`;
}

export const Column = styled.div`
    margin: 0 auto;
    padding: .25rem;
    height: 32rem;
    /* min-width: 80rem;
    max-width: 155rem; */
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        ${({mobile}) => mobile && getWidthGrid(mobile)}
    }

    @media only screen and (min-width: 768px) {
        ${({tablet}) => tablet && getWidthGrid(tablet)}
    }

    @media only screen and (min-width: 1000px) {
        ${({desktop}) => desktop && getWidthGrid(desktop)}
    }
`;


