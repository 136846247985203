import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import Home from '../pages/Home'
import Login from '../pages/Login';
import Clients from '../pages/Clients';
import PrivateRoute from '../services/wAuth';

const Routes = () => (
    <BrowserRouter>
        <Switch>
          <Route exact path='/' component={ Login } />          
          <PrivateRoute path='/home' exact  component={ Home } />          
          <PrivateRoute path='/clients' exact  component={ Clients } />           
        </Switch>
    </BrowserRouter>
  )

  export default Routes;