import React, { useState } from "react";
import api from '../../services/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setIdEmpresa, setNomeEmpresa, setNomeUsuario, setIdUsuario, setEmailUsuario, setNivelUsuario  } from '../../services/auth';
import Alert from '@material-ui/lab/Alert';
import imgLogo from '../../assets/imgs/Falconcomex_01_ok.svg'
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {
    Container,
    SubContainer,
    Container2,
    TextInput,    
    Img,
    Button,
    Icon
} from './styles'

export default function Login(){
    const [ email, setEmail ] = useState('');
    const [ senha, setSenha ] = useState('');    
    const [ loading, setLoading ] = useState(false);
    const [status, setStatusBase] = React.useState("");
    
    async function handleSubmit(){
        try {            
            try{
                await api.post('/api/newLogin',{email, senha})
                .then(res => {
                    console.log("res " + res.status);
                    console.log("res " + res.data);
                    if(res.status===200){
                        
                        if(res.data.status===1){
                            // login(res.data.token);
                            setIdUsuario(res.data.id);
                            setNomeUsuario(res.data.nome);
                            setEmailUsuario(res.data.email);
                            setIdEmpresa(0);
                            setNomeEmpresa("");  
                            setLoading(false);
                            setNivelUsuario(res.data.nivel);
                            window.location.href= '/home'
                        } else{
                            setStatusBase({msg: res.data.msg});
                            setLoading(false);
                            // setTimeout(() => {
                            //     window.location.href= '/'
                            // }, 3000)
                        }
                    }else{
                        setStatusBase({msg: res.data.msg});
                        setLoading(false);                            
                        // setTimeout(() => {                                
                        //     window.location.href= '/'
                        // }, 3000)
                    }
                })    
            }catch(error){
                setStatusBase({msg: error});
                setLoading(false);          
                // setTimeout(() => {                        
                //     window.location.href= '/'
                // }, 3000)
            }           
        } catch (error) {
        setLoading(false);            
        // setTimeout(() => {
        //     window.location.href= '/'
        // }, 3000)
        }
    }
   async  function loadSubmit(){
    if(email !== "" && senha !==""){
        setLoading(true);
        setTimeout(    () => handleSubmit(),
            1000
          )            
    }
        setIdUsuario(0);
        setNomeUsuario("");
        setEmailUsuario("");
        setIdEmpresa(0);
        setNomeEmpresa("");    
        
    }
    
    return (
        <>
        <Container >
            <SubContainer>
            <form >
                <Img src={imgLogo} alt="Logo" />
                <Container2>
                    <Icon>
                        <PersonIcon
                        width="20px"
                        height="20px"
                        onClick={() => {
                        }}
                        />
                    </Icon>
                    <TextInput
                        required 
                        placeholder="E-mail" 
                        id="email" 
                        // value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                </Container2>
                <Container2>
                    <Icon>
                        <LockOutlinedIcon
                        width="10px"
                        height="10px"
                        onClick={() => {
                        }}
                        />
                    </Icon>
                    <TextInput
                        type="password" 
                        className="App__login__form__input" 
                        placeholder="Senha" 
                        required
                        value={senha}
                        onChange={e => setSenha(e.target.value)}
                    />
                </Container2>
                   {status ? <Alert severity="error" key={status.key}
                    >{status.msg}</Alert> : null}
                <Button type="button" 
                    className="App__login__btn" 
                    onClick={loadSubmit}
                    disabled={loading}>{loading?<CircularProgress style={{'color': 'white'}}/>:"ENTRAR"}</Button>
            </form>            
            </SubContainer> 
        </Container>
        </>
    );    
}