import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, LinearProgress, CircularProgress, TableBody } from '@material-ui/core';
import { 
    Container, 
    Row, 
    Column, 
    BoxStyle, 
    BoxTwo,
    ListResume, 
    ListResumeLast, 
    ImgModal,
    Img,
    ImgQtd,
    ImgList, 
    Title, 
    TitleProvaveis,
    TitleLine, 
    Subtitle, 
    SubtitleProvaveis,
    TotalResume,
    TotalIImport,
    TotalDescr
     } from './styles'
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { BarChart, CartesianGrid, XAxis, YAxis, Bar } from 'recharts'
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import imgImport from '../../assets/imgs/imported.svg'
import imgQtdReg from '../../assets/imgs/shipment.svg'
import imgFreight from '../../assets/imgs/freight.svg'
import imgInsurance from '../../assets/imgs/insurance.svg'
import imgMarketed from '../../assets/imgs/marketed.svg'
import imgWeight from '../../assets/imgs/weight.svg'
import imgPackage from '../../assets/imgs/package.svg'
import imgTruck from '../../assets/imgs/truck.svg'
import imgFerry from '../../assets/imgs/ferry.svg'
import imgAir from '../../assets/imgs/airplane.svg'

function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" thickness={4} {...props}  />
        <Box
          top={0}
          left={0}
          bottom={5}
          right={8}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"          
        >
          <Typography variant="caption" component="div" color="textPrimary">
              {/* {`${Math.round(
                props.value,
                )}%`} */}
                 <ImgModal src={imgFerry} alt="" />        
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };
  
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 10,
      marginBottom: 4,
      
    },
    colorPrimary: {
      backgroundColor: '#ffdbb7',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#ffaa55',
      
    },
  }))(LinearProgress);

  const BorderLinearProgressExportador = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 10,
      marginBottom: 4,
      
    },
    colorPrimary: {
      backgroundColor: '#ffb7b7',
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#ff5555',
      
    },
  }))(LinearProgress);

  const BorderLinearProgressNCM = withStyles((theme) => ({
    root: {
      height: 10,    
      marginBottom: 4
    },
    colorPrimary: {
      backgroundColor: '#babaff',
    },
    bar: {
      borderRadius: 1,
      backgroundColor: '#7f7fff',
    },
  }))(LinearProgress);

  const BorderLinearProgressUnidade = withStyles((theme) => ({
    root: {
      height: 10,    
      marginBottom: 4
    },
    colorPrimary: {
      backgroundColor: '#b7ffdb',
    },
    bar: {
      borderRadius: 1,
      backgroundColor: '#55ffaa',
    },
  }))(LinearProgress);
  
  // Inspired by the former Facebook spinners.
  const useStylesFacebook = makeStyles((theme) => ({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#6E84C2',
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
    top1: {
        color: '#F87045',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
      },
      top2: {
        color: '#F2645A',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
      },
      top3: {
        color: '#E875A7',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
      },
    circle: {
      strokeLinecap: 'round',
    },
  }));
  
  function FacebookCircularProgress(props) {
    const classes = useStylesFacebook();    
    return (
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
      
        <CircularProgress
          variant="determinate"
          disableShrink
          value={80}
          className={props.caption === 'MAR' ? classes.top :
                     props.caption === 'ARE' ? classes.top1 :
                     props.caption === 'ROD' ? classes.top2 : classes.top3
                    }
          classes={{
            circle: classes.circle,
          }}
          size={40}
          thickness={4}
          {...props}
        />
        <Box
          top={8}
         
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"          
        >
              <ImgModal src={props.caption === 'MAR' ? imgFerry : 
                            props.caption === 'AER' ? imgAir :
                            props.caption === 'ROD' ? imgTruck : imgPackage } alt="" />        
          
        </Box>

      </div>
    );
  }
   
export default function Dashboard({resume, importer, exporter, ncm, desembaraco, origemAquisicao, modais}){
    const [ totalImp, setTotImp ] = useState(0);
    const [ qtdReg, setQtdReg ] = useState(0);
    const [ qtdComerc, setqtdComerc ] = useState(0);
    const [ frete, setFrete ] = useState(0);
    const [ seguro, setSeguro ] = useState(0);
    const [ pLiq, setPLiq ] = useState(0);
    
    useEffect(() => {
       resume.map( element => {
        setTotImp(element['total']);
        setQtdReg(element['qtdReg']);
        setqtdComerc(element['qtdComerc']);
        setFrete(element['frete']);
        setSeguro(element['Seguro']);
        setPLiq(element['PLiq']);
        return("");
       }) 
    }, []);
    
  
    return (
        <>
            <Container>
                <Row>
                    <Column mobile='6' tablet='12' desktop= '6'>
                        <BoxTwo>
                            <Title>Valor Total Importado no Período</Title>
                            <TitleLine />
                            <ListResumeLast>
                                <Img src={imgImport} alt="" />           
                                 <div>            
                                  <TotalIImport>{totalImp} USD</TotalIImport><br/>
                                  <TotalDescr>Total FOB pago pelo mercado na importação dos produtos desta pesquisa</TotalDescr>
                                </div>
                            </ListResumeLast>
                        </BoxTwo>    
                        
                        <BoxTwo>
                            <Title>Quantidade de Registros</Title>
                            <TitleLine />
                            <ListResumeLast>
                                <ImgQtd src={imgQtdReg} alt="" />
                                <div>
                                  <TotalIImport>{qtdReg}</TotalIImport><br/>
                                  <TotalDescr>Total de registros de importação localizados no período</TotalDescr>
                                </div>
                            </ListResumeLast>
                        </BoxTwo>    
                    </Column>
                    <Column mobile='6' tablet='12' desktop= '6'>
                        <BoxStyle>
                            <div>
                                <Title>Resumo</Title>
                                
                                <Subtitle>Resumo de valores</Subtitle>
                                <TitleLine />
                               
                            </div>
                            <ListResume>
                                 <ImgList src={imgWeight} alt="" />
                                 <div style={{width: '100%'}}>
                                  <div style={{float: 'left'}}>
                                    <Title>Peso Líquido(KG)</Title>
                                  </div>
                                  <div style={{alignContent: 'center', display: 'flex', justifyContent: 'center', float:'right'}}>
                                   <TotalResume TotalResume>{pLiq}</TotalResume>
                                  </div>
                                 </div>
                                 
                            </ListResume>
                            <ListResume>
                              <ImgList src={imgFreight} alt="" />
                                <div style={{width: '100%'}}>
                                  <div style={{float: 'left'}}>
                                    <Title>Frete(USD)</Title>
                                  </div>
                                  <div style={{alignContent: 'center', display: 'flex', justifyContent: 'center', float:'right'}}>
                                    <TotalResume>{frete}</TotalResume>
                                  </div>
                                </div>
                            </ListResume>
                            <ListResume>
                                <ImgList src={imgInsurance} alt="" />
                                <div style={{width: '100%'}}>
                                  <div style={{float: 'left'}}>
                                  <Title>Seguro(USD)</Title>
                                  </div>
                                  <div style={{alignContent: 'center', display: 'flex', justifyContent: 'center', float:'right'}}>
                                    <TotalResume>{seguro}</TotalResume>
                                  </div>
                                </div>
                            </ListResume>
                            <ListResumeLast>
                                <ImgList src={imgMarketed} alt="" />
                                <div style={{width: '100%'}}>
                                  <div style={{float: 'left'}}>
                                <Title>Qntd. Comercializada</Title>
                                </div>
                                <div style={{alignContent: 'center', display: 'flex', justifyContent: 'center', float:'right'}}>
                                <TotalResume>{qtdComerc}</TotalResume>
                                </div>
                                </div>
                            </ListResumeLast>
                        </BoxStyle>
                    </Column>
                </Row>
                <Row>
                    <Column mobile='6' tablet='12' desktop= '6'>
                        <BoxStyle>
                            <div>
                                <Title>Prováveis Importadores</Title>
                                <Subtitle>Principais importadores para sua pesquisa (por valor FOB)</Subtitle>
                                <TitleLine />
                            </div>    
                            <div style={{justifyContent: 'space-between', marginLeft:'.5rem', marginRight:'.1rem', marginTop:'.5rem'}}>
                                {importer.map((row, index) => {
                                  return(
                                    <>
                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <TitleProvaveis key={row.flg_importador_nome}>{row.flg_importador_nome}</TitleProvaveis>   
                                        <SubtitleProvaveis>Valores: {row.total} | Registros: {row.qtd}</SubtitleProvaveis>   
                                      </div>
                                      <BorderLinearProgress variant="determinate" value={Number(row.perc)} />
                                    </>
                                  )
                                })}                               
                            </div>
                        </BoxStyle>
                    </Column>
                    <Column mobile='6' tablet='12' desktop= '6'>
                       <BoxStyle>
                            <div>
                                <Title>Prováveis Exportadores</Title>
                                <Subtitle>Principais exportadores para sua pesquisa (por valor FOB)</Subtitle>
                                <TitleLine />
                            </div>  
                            <div style={{justifyContent: 'space-between', marginLeft:'.5rem', marginRight:'.1rem', marginTop:'.5rem'}}>
                            {exporter.map((row, index) => {
                                  return(
                                    <>
                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <TitleProvaveis>{row.flg_exportador_nome}</TitleProvaveis>   
                                        <SubtitleProvaveis>Valores: {row.total} | Registros: {row.qtd}</SubtitleProvaveis>   
                                      </div>
                                      <BorderLinearProgressExportador variant="determinate" value={Number(row.perc)} />
                                    </>
                                  )
                            })}                                
                            </div>  
                        </BoxStyle>
                    </Column>
                </Row>
                <Row>
                    <Column mobile='6' tablet='12' desktop= '6'>
                      <BoxStyle>
                            <div>
                                <Title>Unidades de Desembaraço</Title>
                                <Subtitle>Ranking das unidades de desembaraço da mais relevante para a menso relevante</Subtitle>
                                <TitleLine />
                            </div>    
                            <div style={{justifyContent: 'space-between', marginLeft:'.5rem', marginRight:'.1rem', marginTop:'.5rem'}}>
                                {desembaraco.map((row, index) => {
                                  return(
                                    <>
                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                          <TitleProvaveis key={row.flg_nome_unid_desembaraco}>{row.flg_nome_unid_desembaraco}</TitleProvaveis>   
                                          <SubtitleProvaveis>{row.total} USD</SubtitleProvaveis>   
                                      </div>
                                      <BorderLinearProgressUnidade variant="determinate" value={Number(row.perc)} />
                                    </>
                                  )
                                })}                                
                            </div>  
                        </BoxStyle>
                       
                    </Column>
                    <Column mobile='6' tablet='12' desktop= '6'>
                      <BoxStyle>
                            <div>
                                <Title>NCM</Title>
                                <Subtitle>Valor total importador por NCM</Subtitle>
                                <TitleLine />
                            </div>    
                            <div style={{justifyContent: 'space-between', marginLeft:'.5rem', marginRight:'.1rem', marginTop:'.5rem'}}>
                                {ncm.map((row, index) => {
                                  return(
                                    <>
                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <TitleProvaveis key={row.ncm}>{row.ncm}</TitleProvaveis>   
                                        <SubtitleProvaveis>{row.total} USD</SubtitleProvaveis>   
                                      </div>
                                      <BorderLinearProgressNCM variant="determinate" value={Number(row.perc)} />
                                    </>
                                  )
                                })}
                            </div>       
                      </BoxStyle>
                    </Column>
                </Row>                
                <Row>
                    <Column mobile='6' tablet='12' desktop= '6'>
                      <BoxStyle>
                            <div>
                                <Title>País de Origem e Aquisição</Title>
                                <Subtitle>Rotas de País de origem e aquisição mais utilizadas junto com valor médio FOB</Subtitle>
                                <TitleLine />
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                  <Table size="small" style={{border: 'none', boxshadow: 'none', fontFamily: 'Poppins'}}>
                                      <TableHead>
                                        <TableRow  style={{borderBottomStyle: 'hidden'}}>
                                          <TableCell style={{fontSize: '.7rem', fontWeight: 'bold'}}></TableCell>
                                          <TableCell style={{fontSize: '.7rem', fontWeight: 'bold'}}>PAÍS DE ORIGEM </TableCell>
                                          <TableCell style={{fontSize: '.7rem', fontWeight: 'bold'}}>PAÍS DE AQUISIÇÃO</TableCell>
                                          <TableCell style={{fontSize: '.7rem', fontWeight: 'bold'}}>VALOR FOB</TableCell>
                                          </TableRow>  
                                      </TableHead>  
                                      <TableBody>
                                        {origemAquisicao.map((row) => {
                                          return(
                                            <>
                                            <TableRow style={{borderTopStyle: 'hidden'}} key={row.perc}>
                                              <TableCell style={{fontSize: '.7rem'}}>{row.perc} %</TableCell>
                                              <TableCell style={{fontSize: '.7rem'}}>{row.flg_nm_pais_origem}</TableCell>
                                              <TableCell style={{fontSize: '.7rem'}}>{row.flg_nm_pais_aquis}</TableCell>
                                              <TableCell style={{fontSize: '.7rem'}}>USD {row.Fob}</TableCell>
                                            </TableRow>  
                                            </>
                                          )
                                        })}
                                      </TableBody>  
                                  </Table>                               
                                </div>
                            </div>    
                        </BoxStyle>
                    </Column>
                    <Column mobile='6' tablet='12' desktop= '6'>
                        <BoxStyle>
                            <div>
                                <Title>Modais</Title>
                                <Subtitle>Distribuição da quantidade de registros por modal de transporte</Subtitle>
                                <TitleLine />
                                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginLeft:'2rem', marginRight:'2rem', marginTop:'3rem'}}>
                                    {modais.map((row, index) => {
                                      return(
                                        <>
                                          <div>
                                            <FacebookCircularProgress size="4rem" value={parseInt(row.perc)} caption={row.TIPO} />
                                            <br/>
                                            <Title>{row.modal === 'ENTRADA/SAÍDA FICTA' ? "OUTROS" : row.modal}</Title>
                                            <Subtitle>{row.perc}%</Subtitle>
                                            <Subtitle>{row.totreg}</Subtitle>
                                          </div>
                                        </>
                                      )
                                    })}                                
                                </div>
                            </div>    
                        </BoxStyle>
                    </Column>
                </Row>              
            </Container>
            
        </>
    );

}