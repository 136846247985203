import styled from 'styled-components';

export const Container = styled.div`
    /* max-width: 1360px; */
    padding-right: 15px;
    padding-left:15px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    &:before,
    &:after{
        content: " "            ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;

export const BoxStyle = styled.div`
    height: 18rem;
    overflow: auto;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    /* display: flex; */
    margin-bottom: 1rem;
    padding: .375rem .75rem;
    font-size: 1rem;      
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
`;

export const BoxTwo = styled.div`
    height: 8.5rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    /* display: flex; */
    margin-bottom: 1rem;
    padding: .175rem .75rem;
    font-size: 1rem;      
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    
    
    &:first-child{
        margin-bottom: 1rem;
    }
`;

export const ImgModal = styled.img`
    display: block;
    color: #fff;
    height: 2rem;
    width: 2rem;
    margin-top: .5rem;
    margin-left: 1rem;
   
`;

export const Img = styled.img`
    display: flex;
    height: 6rem;
    width: 5rem;
    margin-top: .5rem;
    /* margin-left: 1rem; */
   
`;

export const ImgQtd = styled.img`
    display: flex;
    height: 5rem;
    width: 5rem;
    margin-top: .5rem;
    /* margin-left: 1rem; */
   
`;

export const ImgList = styled.img`
    display: block;
    height: 2.5rem;
    width: 2.5rem;
    margin-top: .5rem;
    margin-left: 1.5rem;
    margin-right: 1rem;
    margin-bottom: .8rem;
`;

export const ListResume = styled.div`
    font-family: 'Poppins', sans-serif;
    display: flex;
    height: 3.5rem;
    align-items: center;     
    border-bottom: 1px solid rgba(73, 80, 87, .3);
    
`;

export const ListResumeLast = styled.div`
    display: flex;
    align-items: center;
    /* justify-content: center;     */
       
`;

export const Row = styled.div`
    width:100%;
    height:auto;
    float:left;
    box-sizing: border-box;
    &:before,
    &:after{
        content: " " ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;

export const Title = styled.span`
    font-family: 'Poppins', sans-serif;
    /* margin-left: .5rem; */
    font-size: 0.75rem;
    font-weight: bold;
    text-align: left;
`;

export  const TitleLine = styled.span`
    &::after{
        white-space: pre;        
    }
    display: block;
    border-bottom: .02rem solid #495057;
    opacity: .3;
`;

export const TitleProvaveis = styled.span`
    font-family: 'Poppins', sans-serif;
    margin-left: .1rem;    
    font-size: 0.7rem;
    font-weight: bold;
    text-align: left;
    
`;


export const TotalResume = styled.span`
    font-family: 'Poppins', sans-serif;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 0.75rem;
    text-align: right;
    margin-right: 2rem;
`;

export const TotalIImport = styled.span`
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    align-items:center;
    /* justify-content: center; */
    font-size: 1.5rem;
    text-align: right;
    margin-right: 2rem;
    margin-left: 4rem;
`;

export const TotalDescr = styled.span`
    font-family: 'Poppins', sans-serif;
    margin-left: 4rem;    
    font-size: 0.65rem;   
`;

export const Subtitle = styled.span`
    font-family: 'Poppins', sans-serif;
    &::after{
        white-space: pre;
    }
    /* margin-left: .5em; */
    display: block;
    font-size: 0.75rem;    
`;

export const SubtitleProvaveis = styled.span`
    &::after{
        white-space: pre;
    }
    font-family: 'Poppins', sans-serif;    
    margin-left: .5em;
    display: block;
    font-size: 0.7rem;    
`;

function getWidthGrid(value){
    if (!value) return;

    let width = value / 12 * 100;
    return `width ${width}%;`;
}

export const Column = styled.div`
    float: left;
    padding: .25rem;
    min-height: 1px;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        ${({mobile}) => mobile && getWidthGrid(mobile)}
    }

    @media only screen and (min-width: 768px) {
        ${({tablet}) => tablet && getWidthGrid(tablet)}
    }

    @media only screen and (min-width: 1000px) {
        ${({desktop}) => desktop && getWidthGrid(desktop)}
    }
`;