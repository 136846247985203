import React, { useState, useEffect} from "react";
import Container from '../../components/Container'
import api from '../../services/api';
import '../../assets/fonts/GOTHIC.TTF'; 
import Tablecomex from '../../components/Table/Tablecomex'
import Dashboard from "../../components/Dashboard";
import TableClients from "../../components/Table/TableClients";
import imgLogo from '../../assets/imgs/Falconcomex_01_ok.svg'
import { getEmailUsuario, getNivelUsuario } from '../../services/auth';
import Modal from '../../components/Modal/Modal'

import { 
    Input,
    InputNCM,
    InputTwo,
    ContainerHome,
    Row,
    Button,
    ButtonClients,
    ButtonMonthPer,
    ButtonMonth,
    ButtonMonthPerSelect,
    ButtonMonthSelect,
    ImgLogo,
    BoxStyle,
    ContainerBox,
    ContainerBox2,
    Select,
    Periodo,
    Aviso
} from './styles'


  function getyear(){
    var data = new Date(),
        dia = data.getDate().toString(),
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes,
        anoF = data.getFullYear() ;    
        if(mesF === '01' && dia <= 20){
           anoF = anoF - 1; 
        }
    return anoF;   
  }
  function monthAtual(){
    var data = new Date(),
        dia = data.getDate().toString(),
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length === 1) ? '0'+mes : mes;
        if(dia <= 20){
          mesF = mesF - 1;
        }
    return mesF  ;   
  }

  const mesAtual = monthAtual();
  const setAnoAtual = getyear();
  const setAnoAnt = getyear() -1;



const Home = () =>  {
  const [showNoDatas, setShowNoData] = React.useState(false);
  const [showButtons, setShowButtons] = React.useState(false);
  const [showDashboard, setShowDashboard] = React.useState(false);
  const [showDetalhe, setShowDetalhe] = React.useState(false);
  const [showLoading, setLoading] = React.useState(false);  
  const [ getNCM, setNCM ] = useState('');
  const [ getIncoterm, setIncoterm ] = useState('');
  const [ getOrigem, setOrigem ] = useState('');
  const [ getAquisicao, setAquisicao ] = useState('');
  const [ getModal, setModal ] = useState('');
  const [ getImportador, setImportador] = useState('');
  const [ getExportador, setExportador ] = useState('');
  const [ getNotificado, setNotificado ] = useState('');
  const [ getProduto, setProduto ] = useState('');
  const [ getDesembaraco, setDesembaraco ] = useState('');
  const [dataFilter, setDataFilter] = useState([]);
  const [dataResume, setDataResume] = useState([]);
  const [dataImport, setDataImport] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [dataOrigemAquisicao, setDataOrigemAquisicao] = useState([]);
  const [ per3, setPer3] = React.useState(false);
  const [ per6, setPer6] = React.useState(false);
  const [ per9, setPer9] = React.useState(false);
  const [ per12, setPer12] = React.useState(false);
  const [ isSelectedYearAnt, setIsSelectYearAnt] =useState(false);
  const [ isSelectedYearAtual, setIsSelectYearAtual] =useState(true);  
  const [ monthJan, setMonthJan] =useState(false);
  const [ monthFev, setMonthFev] =useState(false);
  const [ monthMar, setMonthMar] =useState(false);
  const [ monthAbr, setMonthAbr] =useState(false);
  const [ monthMai, setMonthMai] =useState(false);
  const [ monthJun, setMonthJun] =useState(false);
  const [ monthJul, setMonthJul] =useState(false);
  const [ monthAgo, setMonthAgo] =useState(false);
  const [ monthSet, setMonthSet] =useState(false);
  const [ monthOut, setMonthOut] =useState(false);
  const [ monthNov, setMonthNov] =useState(false);
  const [ monthDez, setMonthDez] =useState(false);
  const [dataNCM, setDataNCM] = useState([]);  
  const [dataDesembaraco, setDataDesembaraco] = useState([]);
  const [dataModal, setDataModal] = useState([]);    
  const [ getMaisDeUm, setMaisDeUm ] = useState(false);
  const [ showClients, setShowClients ] = useState(false);
  const [getState, setState] = React.useState(false); 
  const [getTitle, setTitle] = React.useState('');
  const [ getUsuarioNome, setUsuarioNome ] = React.useState('');  
  const [ setUsuarioEmpresa ] = React.useState('');    
  const [ setUsuarioTelefone ] = React.useState('');   
  const [ setUsuarioEmail ] = React.useState('');   
  const [ setUsuarioSenha ] = React.useState('');   
  let varios = false;
  let dt1 = '';
  let dt2 = '';
  let stringFiltro;
  let stringSubFiltro;
  let meses = '';
  

  useEffect(() => { 
    if(mesAtual === '01'){
      setMonthJan(true);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '02'){
      setMonthJan(false);
      setMonthFev(true)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '03'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(true)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '04'){
      setMonthJan(false)
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(true)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '05'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(true)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '06'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(true)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '07'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(true)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '08'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(true)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '09'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(true)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '10'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(true)
      setMonthNov(false)
      setMonthDez(false)
    }
    if(mesAtual === '11'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(true)
      setMonthDez(false)
    }
    if(mesAtual === '12'){
      setMonthJan(false);
      setMonthFev(false)
      setMonthMar(false)
      setMonthAbr(false)
      setMonthMai(false)
      setMonthJun(false)
      setMonthJul(false)
      setMonthAgo(false)
      setMonthSet(false)
      setMonthOut(false)
      setMonthNov(false)
      setMonthDez(true)
    }    
  }, []);


  const handleGetFilter = () => {
      setShowButtons(false);
      setShowClients(false);
      setShowNoData(false);
      setShowDetalhe(false);
      setShowDashboard(false);
      setLoading(true);
      returnMonth();
      // console.log("VARIOS " + varios)
      if(varios){
        stringFiltro = ` WHERE fg2.flg_anomes in (${meses})`;
        stringSubFiltro = ` WHERE fg.flg_anomes in (${meses})`;  
      }else{
        stringFiltro = ` WHERE fg2.flg_anomes = '${returnMonth()}'`;
        stringSubFiltro = ` WHERE fg.flg_anomes = '${returnMonth()}'`;
      }
      
      
      if(getNCM !== ''){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_cdncm_compl like '%${getNCM}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_cdncm_compl like '%${getNCM}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_cdncm_compl like '%${getNCM}%'`;
        }
        
      }      
      
      if(getIncoterm !== '' && getIncoterm !== '0'){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_incoterm = '${getIncoterm}'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_incoterm = '${getIncoterm}'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_incoterm = '${getIncoterm}'`;
        }
      }

      if(getOrigem !== ''){        
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_nm_pais_origem like '%${getOrigem}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_nm_pais_origem like '%${getOrigem}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_nm_pais_origem like '%${getOrigem}%'`;
        }
      }

      if(getAquisicao !== ''){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_nm_pais_aquis like '%${getAquisicao}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_nm_pais_aquis like '%${getAquisicao}%'`;
        }
      }
      if(getModal !== ''  && getModal !== '0'){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_via_transp = '${getModal}'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_via_transp = '${getModal}'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_via_transp = '${getModal}'`;
        }
      }

      if(getImportador !== ''){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_importador_nome like '%${getImportador}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_importador_nome like '%${getImportador}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_importador_nome like '%${getImportador}%'`;
        }
      }

      if(getExportador !== ''){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_exportador_nome like '%${getExportador}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_exportador_nome like '%${getExportador}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_exportador_nome like '%${getExportador}%'`;
        }
      }

      if (getNotificado !== ''){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_nome_adquirente like '%${getNotificado}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_nome_adquirente like '%${getNotificado}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_nome_adquirente like '%${getNotificado}%'`;
        }
      }

      if(getProduto !== ""){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_desc_prodt like '%${getProduto}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_desc_prodt = ${getProduto}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_desc_prodt = ${getProduto}%'`;
        }
      }

      if(getDesembaraco !== ''){
        if(stringFiltro === ''){
          stringFiltro = ` WHERE fg2.flg_nome_unid_desembaraco like '%${getDesembaraco}%'`;
        }else{
          stringFiltro =  stringFiltro + ` AND fg2.flg_nome_unid_desembaraco like '%${getDesembaraco}%'`;
          stringSubFiltro =  stringSubFiltro + ` AND fg.flg_nome_unid_desembaraco like '%${getDesembaraco}%'`;
        }
      }

      if (getNivelUsuario() === '2'){        
        stringFiltro = stringFiltro + " AND (fg2.flg_importador_nome not like '%WINNING%') AND (fg2.flg_exportador_nome not like '%WINNING%')";
        stringSubFiltro = stringSubFiltro + " AND (fg.flg_importador_nome not like '%WINNING%') AND (fg.flg_exportador_nome not like '%WINNING%')";
      }
      
      const getDataComex = async () => {        
         let per = await returnMonth();
         console.log(	"periodo: " + per+ "dat1: " + dt1+ "dat2: " + dt2+ "maisDeum: " + varios+ "qualFiltro: " + stringSubFiltro+ "ncm: " + getNCM+ "importador: " + getImportador+ "origem: " + getOrigem+ 
         "aquisicao: " + getAquisicao+ "modalidade: " + getModal+ "exportador: " + getExportador+ "notificado: " + getNotificado+ "prodt: " + getProduto+ "desembaraco: " + getDesembaraco)
         await api.post('/api/comex/',{mode: 'no-cors',headers:{'Content-Type': 'application/json'}, "periodo": per, "dat1": dt1, "dat2": dt2, "maisDeum": varios, "qualFiltro": stringSubFiltro, "ncm": getNCM, "importador": getImportador, "origem": getOrigem, 
                                        "aquisicao": getAquisicao, "modalidade": getModal, "exportador": getExportador, "notificado": getNotificado, "prodt": getProduto, "desembaraco": getDesembaraco, "email": getEmailUsuario()})
            .then(res => {   
              // console.log("REs1 -- " + res.status);
              console.log("REs1 -- " + res.status);
              if (res.status === 204){
                // console.log("REs -- " + res.status);
                setShowNoData(true);
                setLoading(false);                
                setShowButtons(false);
                setShowDashboard(false);
                setShowClients(false);
              }else{
                setShowNoData(false)                
                
                if(getMaisDeUm){
                  setTimeout(function(){ 
                    getDados();
                  }, 120000);
                }else{
                  setTimeout(function(){ 
                    getDados();
                  }, 10000);
                }
              }
        });    		
      }
      getDataComex();  
      // getDados(); 
  }

  function getDados(){    
    setDataFilter([])
    getDataBanco();
    getDataDashboard();
    getDataImport();
    getDataExport();
    getDataNCM();
    getDataDesembaraco();
    getOrigemAquisicao();
    getDataModal();    
  }
  
  const getDataBanco = async () => {        //85414032
    // console.log("stringSubFiltro " + stringSubFiltro)
    
    await api.post('/api/portalComex/',{headers:{'Content-Type': 'application/json'}, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {            
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{
        
        setDataFilter(res.data.result);
      }

      
    });    		
  }
  const getDataDashboard = async () => {    
    await api.post('/api/getResume/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "nivel":getNivelUsuario()})
    .then(res => {   
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{                 
        setDataResume(res.data.result);
      }
      
    });    		
  }

  const getDataImport = async () => {
    await api.post('/api/getImport/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {      
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{              
        setDataImport(res.data.result);
      }
      
    });    		
  }

  const getDataExport = async () => {
    await api.post('/api/getExport/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {      
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{              
        setDataExport(res.data.result);
      }
      
    });    		
  }

  const getDataNCM = async () => {
    await api.post('/api/getNCM/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {       
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{             
        setDataNCM(res.data.result);
      }
      
    });    		
  }

  const getDataDesembaraco = async () => {
    await api.post('/api/getDesembaraco/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {        
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{            
        setDataDesembaraco(res.data.result);
      }
    });    		
  }

  const getOrigemAquisicao = async () => {
    await api.post('/api/getOrigemAquisicao/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {         
      if (res.status !== 200){
        setShowNoData(true);
        setShowButtons(false);
        setLoading(false);
      }else{           
        setDataOrigemAquisicao(res.data.result);
      }
      
    });   
  }

  const getDataModal = async () => {
    await api.post('/api/getModal/',{headers:{'Content-Type': 'application/json'}, "filtro": stringFiltro, "subFiltro": stringSubFiltro, "nivel":getNivelUsuario()})
    .then(res => {    
      if (res.status !== 200){
        setShowNoData(true);
        setLoading(false);
        setShowButtons(false);
        setShowDashboard(false);
        setShowClients(false);
      }else{                
        setDataModal(res.data.result);
        handleShowButton();
        setShowDashboard(true);
      }
      
    }); 
   
    setLoading(false);
    setShowDetalhe(false);
  }

  function returnAnoMes(){
    let ano;
    isSelectedYearAtual ? ano = setAnoAtual : isSelectedYearAnt? ano = setAnoAnt : ano ='';   
    return ano;
  }

  function returnMonth(){
    let month = '';
    // let ano = returnAnoMes();
    let ano = 2021;
    const menos = per6 ? 6 : per3 ? 3 : per9 ? 9 : per12 ? 12 : 0;
    const teste = monthAtual() - menos;//56031240
    
    // if(menos > 0){
    //   if (teste === -1){
    //     month =  setAnoAnt+'12'
    //   }else if (teste === -2){
    //     month =  setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -3){
    //     month =  setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -4){
    //     month =  setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -5){
    //     month =  setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -6){
    //     month =  setAnoAnt+'07,'+setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -7){
    //     month =  setAnoAnt+'06,'+setAnoAnt+'07,'+setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -8){
    //     month =  setAnoAnt+'05,'+setAnoAnt+'06,'+setAnoAnt+'07,'+setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -9){
    //     month =  setAnoAnt+'04,'+ setAnoAnt+'05,'+setAnoAnt+'06,'+setAnoAnt+'07,'+setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -10){
    //     month =  setAnoAnt+'03,'+setAnoAnt+'04,'+ setAnoAnt+'05,'+setAnoAnt+'06,'+setAnoAnt+'07,'+setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }else if (teste === -11){
    //     month =  setAnoAnt+'02,'+setAnoAnt+'03,'+setAnoAnt+'04,'+ setAnoAnt+'05,'+setAnoAnt+'06,'+setAnoAnt+'07,'+setAnoAnt+'08,'+setAnoAnt+'09,'+setAnoAnt+'10,'+setAnoAnt+'11,'+setAnoAnt+'12'
    //   }
    // }

    if(monthJan){
      if(month !== ""){
        month = month + ", "+ ano+'01'
      }else{
        month = ano+'01'
      }
    }
    if(monthFev){
      if(month !== ""){
        month = month + ", "+ ano+'02'
      }else{
        month = ano+'02'
      }
    }
    if(monthMar){
      if(month !== ""){
        month = month + ", "+ ano+'03'
      }else{
        month = ano+'03'
      }
    }
    if(monthAbr){
      if(month !== ""){
        month = month + ", "+ ano+'04'
      }else{
        month = ano+'04'
      }
    }
    if(monthMai){
      if(month !== ""){
        month = month + ", "+ ano+'05'
      }else{
        month = ano+'05'
      }
    }
    if(monthJun){
      if(month !== ""){
        month = month + ", "+ ano+'06'
      }else{
        month = ano+'06'
      }
    }
    if(monthJul){
      if(month !== ""){
        month = month + ", "+ ano+'07'
      }else{
        month = ano+'07'
      }
    }
    if(monthAgo){
      if(month !== ""){
        month = month + ", "+ ano+'08'
      }else{
        month = ano+'08'
      }
    }
    if(monthSet){
      if(month !== ""){
        month = month + ", "+ ano+'09'
      }else{
        month = ano+'09'
      }
    }
    if(monthOut){
      if(month !== ""){
        month = month + ", "+ ano+'10'
      }else{
        month = ano+'10'
      }
    }
    if(monthNov){
      if(month !== ""){
        month = month + ", "+ ano+'11'
      }else{
        month = ano+'11'
      }
    }
    if(monthDez){
      if(month !== ""){
        month = month + ", "+ ano+'12'
      }else{
        month = ano+'12'
      }
    }    
    let final;
    if(month.length > 6){
      //console.log(month);
      meses = month;
      varios = true;
      const splitM = month.split(",");
      
      if(splitM.length > 1){
        //splitM[0]+','+splitM[splitM.length-1];    
         final = splitM[0]+','+splitM[splitM.length-1]; 
         final = splitM[0]+"','"+splitM[splitM.length-1];  
         dt1 = splitM[0];         
         dt2 = splitM[splitM.length-1];
         setMaisDeUm(true);
      }else{
        dt1 = splitM;
        setMaisDeUm(false);
      }
    }else{
      varios = false;
      final = month
      dt1 = month;
      setMaisDeUm(false);
    }        
    return final;
  }
 
  const handleShowButton = () => {
    setShowButtons(true);
  }

  const handleShowDashboard = () => {
    setShowDashboard(true);
    setShowDetalhe(false);
  }

  const handleShowDetalhe = () => {
    setShowDashboard(false);
    setShowDetalhe(true);
  }

  const ChangeMonth = (val) => {
      if (val === 'JAN') {
        !monthJan ? setMonthJan(true) : setMonthJan(false);
      }
      if (val === 'FEV'){
        !monthFev ? setMonthFev(true) : setMonthFev(false);
      }
      if (val === 'MAR') {
        !monthMar ? setMonthMar(true) : setMonthMar(false);      
      }
      if (val === 'ABR'){
        !monthAbr ? setMonthAbr(true) : setMonthAbr(false);  
      }
      if (val === 'MAI') {
        !monthMai ? setMonthMai(true) : setMonthMai(false);   
      }
      if (val === 'JUN'){
        !monthJun ? setMonthJun(true) : setMonthJun(false);
      }
      if (val === 'JUL') {
        !monthJul ? setMonthJul(true) : setMonthJul(false); 
      }
      if (val === 'AGO'){
        !monthAgo ? setMonthAgo(true) : setMonthAgo(false);
      }
      if (val === 'SET') {
        !monthSet ? setMonthSet(true) : setMonthSet(false);
      }
      if (val === 'OUT'){
        !monthOut ? setMonthOut(true) : setMonthOut(false);
      }
      if (val === 'NOV') {
        !monthNov ? setMonthNov(true) : setMonthNov(false);
      }
      if (val === 'DEZ'){
        !monthDez ? setMonthDez(true) : setMonthDez(false);
      }
    }

  const ChangeMonthPer = (val) => {        
    if (val === '3') {
      if(!per3){
        
        // if(mesAtual === 12){
        //   // setMonthDez(true)
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        // }else if(mesAtual === 11){
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        // }else if(mesAtual === 10){
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        // }else if(mesAtual === 9){
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        // }else if(mesAtual === 8){
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        // }else if(mesAtual === 7){          
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        // }else if(mesAtual === 6){
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        // }else if(mesAtual === 5){
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        // }else if(mesAtual === 4){
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true) 
        // }else if(mesAtual === 3){
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === 2){
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === 1){
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
         
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setMonthAgo(false)
        setMonthJul(false)
        setMonthJun(false)
        setMonthMai(false)
        setMonthAbr(false)
        setMonthMar(false)
        setMonthFev(false)
        setMonthJan(false) 
        setMonthNov(true)
        setMonthOut(true)
        setMonthSet(true)
        setPer3(true) 
        setPer6(false) 
        setPer9(false)
        setPer12(false)
      }else{
        // if(mesAtual === '12'){
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        // }else if(mesAtual === '11'){
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        // }else if(mesAtual === '10'){
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        // }else if(mesAtual === '09'){
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        // }else if(mesAtual === '07'){
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        // }else if(mesAtual === '06'){
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        // }else if(mesAtual === '05'){
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        // }else if(mesAtual === '04'){
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false) 
        // }else if(mesAtual === '03'){
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)
        // }else if(mesAtual === '02'){
        //   setMonthFev(false) 
        //   setMonthJan(false)
        // }else if(mesAtual === '01'){
        //   setMonthJan(false)
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setPer3(false)
      }
    }
    if (val === '6') {
      if(!per6){
        // if(mesAtual === '12'){
        //   setMonthDez(true)
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        // }else if(mesAtual === '11'){
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        // }else if(mesAtual === '10'){
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)          
        // }else if(mesAtual === '09'){
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        // }else if(mesAtual === '07'){          
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        // }else if(mesAtual === '06'){
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '05'){
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '04'){
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '03'){
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '02'){
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '01'){
        //   setMonthJan(true)
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setMonthAgo(false)
        setMonthJul(false)
        setMonthJun(false)
        setMonthMai(false)
        setMonthAbr(false)
        setMonthMar(false)
        setMonthFev(false)
        setMonthJan(false) 
        setMonthNov(true)
        setMonthOut(true)
        setMonthSet(true)
        setMonthAgo(true)
        setMonthJul(true)
        setMonthJun(true)
        setPer3(false)
        setPer6(true) 
        setPer9(false)
        setPer12(false)
      }else{
        // if(mesAtual === '12'){
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        // }else if(mesAtual === '11'){
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        // }else if(mesAtual === '10'){
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)          
        // }else if(mesAtual === '09'){
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        // }else if(mesAtual === '07'){          
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        // }else if(mesAtual === '06'){
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '05'){
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        //   setMonthDez(false)
        // }else if(mesAtual === '04'){
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        // }else if(mesAtual === '03'){
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        // }else if(mesAtual === '02'){
        //   setMonthFev(false) 
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        // }else if(mesAtual === '01'){
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setMonthAgo(false)
        setMonthJul(false)
        setMonthJun(false)
        setPer6(false)
      }
    }
    if (val === '9') {
      if(!per9){
        // if(mesAtual === '12'){
        //   setMonthDez(true)
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        // }else if(mesAtual === '11'){
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        // }else if(mesAtual === '10'){
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)  
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)        
        // }else if(mesAtual === '09'){
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '07'){          
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '06'){
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '05'){
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '04'){
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '03'){
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '02'){
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '01'){
        //   setMonthJan(true)
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setMonthAgo(false)
        setMonthJul(false)
        setMonthJun(false)
        setMonthMai(false)
        setMonthAbr(false)
        setMonthMar(false)
        setMonthFev(false)
        setMonthJan(false) 
        setMonthNov(true)
        setMonthOut(true)
        setMonthSet(true)
        setMonthAgo(true)
        setMonthJul(true)
        setMonthJun(true)
        setMonthMai(true)
        setMonthAbr(true)
        setMonthMar(true)
        setPer3(false)
        setPer6(false)
        setPer9(true) 
        setPer12(false)
      }else{
        // if(mesAtual === '12'){
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        // }else if(mesAtual === '11'){
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        // }else if(mesAtual === '10'){
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)  
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)        
        // }else if(mesAtual === '09'){
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        //   setMonthDez(false)
        // }else if(mesAtual === '07'){          
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        // }else if(mesAtual === '06'){
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        // }else if(mesAtual === '05'){
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        // }else if(mesAtual === '04'){
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        // }else if(mesAtual === '03'){
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        // }else if(mesAtual === '02'){
        //   setMonthFev(false) 
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        // }else if(mesAtual === '01'){
        //   setMonthJan(false)
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setMonthAgo(false)
        setMonthJul(false)
        setMonthJun(false)
        setMonthMai(false)
        setMonthAbr(false)
        setMonthMar(false)
        setPer9(false)
      }
    }
    if (val === '12') {
      if(!per12){
        // if(mesAtual === '12'){
        //   setMonthDez(true)
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '11'){
        //   setMonthNov(true)
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '10'){
        //   setMonthOut(true)
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)  
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)     
        //   setMonthJan(true)  
        // }else if(mesAtual === '09'){
        //   setMonthSet(true)
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(true)
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '07'){          
        //   setMonthJul(true)
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '06'){
        //   setMonthJun(true)
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '05'){
        //   setMonthMai(true)
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true)
        //   setMonthJan(true)
        // }else if(mesAtual === '04'){
        //   setMonthAbr(true)
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '03'){
        //   setMonthMar(true)
        //   setMonthFev(true) 
        //   setMonthJan(true)
        // }else if(mesAtual === '02'){
        //   setMonthFev(true) 
        //   setMonthJan(true)          
        // }else if(mesAtual === '01'){
        //   setMonthJan(true)
        // }
        setMonthNov(true)
        setMonthOut(true)
        setMonthSet(true)
        setMonthAgo(true)
        setMonthJul(true)
        setMonthJun(true)
        setMonthMai(true)
        setMonthAbr(true)
        setMonthMar(true)
        setMonthFev(true)
        setMonthJan(true)
        setPer3(false)
        setPer6(false)
        setPer9(false)
        setPer12(true) 
      }else{
        // if(mesAtual === '12'){
        //   setMonthDez(false)
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '11'){
        //   setMonthNov(false)
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)          
        // }else if(mesAtual === '10'){
        //   setMonthOut(false)
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)  
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)     
        //   setMonthJan(false)             
        // }else if(mesAtual === '09'){
        //   setMonthSet(false)
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '08'){
        //   setMonthAgo(false)
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '07'){          
        //   setMonthJul(false)
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '06'){
        //   setMonthJun(false)
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)
        // }else if(mesAtual === '05'){
        //   setMonthMai(false)
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false)
        //   setMonthJan(false)          
        // }else if(mesAtual === '04'){
        //   setMonthAbr(false)
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)          
        // }else if(mesAtual === '03'){
        //   setMonthMar(false)
        //   setMonthFev(false) 
        //   setMonthJan(false)          
        // }else if(mesAtual === '02'){
        //   setMonthFev(false) 
        //   setMonthJan(false)          
        // }else if(mesAtual === '01'){
        //   setMonthJan(false)
        // }
        setMonthNov(false)
        setMonthOut(false)
        setMonthSet(false)
        setMonthAgo(false)
        setMonthJul(false)
        setMonthJun(false)
        setMonthMai(false)
        setMonthAbr(false)
        setMonthMar(false)
        setMonthFev(false)
        setMonthJan(false) 
        setPer12(false)
      }
    }
  }

  const handleSelectYear = (value) => {    
    if(value === 1){
      if(!isSelectedYearAnt){
        setIsSelectYearAnt(true);
      }else{
        setIsSelectYearAnt(false);
      }
    }
    if(value === 2){
      if(!isSelectedYearAtual){
        setIsSelectYearAtual(true);
        if(mesAtual === '12'){
          setMonthDez(true)
          setMonthNov(true)
          setMonthOut(true)
          setMonthSet(true)
          setMonthAgo(true)
          setMonthJul(true)
          setMonthJun(true)
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)
        }else if(mesAtual === '11'){
          setMonthNov(true)
          setMonthOut(true)
          setMonthSet(true)
          setMonthAgo(true)
          setMonthJul(true)
          setMonthJun(true)
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)          
        }else if(mesAtual === '10'){
          setMonthOut(true)
          setMonthSet(true)
          setMonthAgo(true)
          setMonthJul(true)
          setMonthJun(true)
          setMonthMai(true)  
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)     
          setMonthJan(true)                
        }else if(mesAtual === '09'){
          setMonthSet(true)
          setMonthAgo(true)
          setMonthJul(true)
          setMonthJun(true)
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)          
        }else if(mesAtual === '08'){
          setMonthAgo(true)
          setMonthJul(true)
          setMonthJun(true)
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)          
        }else if(mesAtual === '07'){          
          setMonthJul(true)
          setMonthJun(true)
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)          
        }else if(mesAtual === '06'){
          setMonthJun(true)
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)
        }else if(mesAtual === '05'){
          setMonthMai(true)
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true)
          setMonthJan(true)
        }else if(mesAtual === '04'){
          setMonthAbr(true)
          setMonthMar(true)
          setMonthFev(true) 
          setMonthJan(true)
        }else if(mesAtual === '03'){
          setMonthMar(true)
          setMonthFev(true) 
          setMonthJan(true)
        }else if(mesAtual === '02'){
          setMonthFev(true) 
          setMonthJan(true)
        }else if(mesAtual === '01'){
          setMonthJan(true)
        }
      }else{
        setIsSelectYearAtual(false);
        if(mesAtual === '12'){
          setMonthDez(false)
          setMonthNov(false)
          setMonthOut(false)
          setMonthSet(false)
          setMonthAgo(false)
          setMonthJul(false)
          setMonthJun(false)
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)
        }else if(mesAtual === '11'){
          setMonthNov(false)
          setMonthOut(false)
          setMonthSet(false)
          setMonthAgo(false)
          setMonthJul(false)
          setMonthJun(false)
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)          
        }else if(mesAtual === '10'){
          setMonthOut(false)
          setMonthSet(false)
          setMonthAgo(false)
          setMonthJul(false)
          setMonthJun(false)
          setMonthMai(false)  
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)     
          setMonthJan(false)                
        }else if(mesAtual === '09'){
          setMonthSet(false)
          setMonthAgo(false)
          setMonthJul(false)
          setMonthJun(false)
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)          
        }else if(mesAtual === '08'){
          setMonthAgo(false)
          setMonthJul(false)
          setMonthJun(false)
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)          
        }else if(mesAtual === '07'){          
          setMonthJul(false)
          setMonthJun(false)
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)          
        }else if(mesAtual === '06'){
          setMonthJun(false)
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)
        }else if(mesAtual === '05'){
          setMonthMai(false)
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false)
          setMonthJan(false)
        }else if(mesAtual === '04'){
          setMonthAbr(false)
          setMonthMar(false)
          setMonthFev(false) 
          setMonthJan(false)
        }else if(mesAtual === '03'){
          setMonthMar(false)
          setMonthFev(false) 
          setMonthJan(false)
        }else if(mesAtual === '02'){
          setMonthFev(false) 
          setMonthJan(false)
        }else if(mesAtual === '01'){
          setMonthJan(false)
        }
      }
    }

 }

 const handleClickLogo =() => {
   setShowDashboard(true);
   setShowDetalhe(false);
   setShowClients(false);
 }


//  const handleClickclients = () => {
//   setShowClients(true);
//   setShowDetalhe(false);
//   setShowDashboard(false);
//  }

 const hideModal = () => {
  setState(false);
  setUsuarioNome('');  
  setUsuarioEmpresa('');    
  setUsuarioTelefone('');   
  setUsuarioEmail('');   
  setUsuarioSenha(''); 
}   

const handleClickOpenModal = (event, data) => { 
  setTitle("Adicionar novo Usuário");
  setState(true);
}

const handleClickSave = () =>{
  /*
    const [ getUsuarioNome, setUsuarioNome ] = React.useState('');  
  const [ getUsuarioEmpresa, setUsuarioEmpresa ] = React.useState('');    
  const [ getUsuarioTelefone, setUsuarioTelefone ] = React.useState('');   
  const [ getUsuarioEmail, setUsuarioEmail ] = React.useState('');   
  const [ getUsuarioSenha, setUsuarioSenha ] = React.useState('');   
  */
  //console.log("getUsuarioNome - "+getUsuarioNome + " getUsuarioNome - "+getUsuarioEmpresa + " getUsuarioTelefone - "+getUsuarioTelefone + " getUsuarioEmail - "+getUsuarioEmail +  " getUsuarioSenha - "+getUsuarioSenha);
}

  return (
    
    <>
   
      <ContainerHome >
        <BoxStyle>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap',float: 'left'}}>
          <div >
          <ImgLogo src={imgLogo} alt="" onClick={handleClickLogo}/>
          </div>
          {/* {getNivelUsuario() === '1'?
          <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: "100%"}}>
          <ButtonClients type="button" onClick={handleClickclients}>   
            CLIENTES
          </ButtonClients>
          </div>
          :
          ""
          } */}
        </div>
            <ContainerBox>             
              <Periodo>
                <div style={{width: '17rem'}}>
                {per3 ? 
                  <ButtonMonthPerSelect type="button" value="3" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 3 meses
                  </ButtonMonthPerSelect>
                  :  
                  <ButtonMonthPer ButtonMonth  value="3" type="button" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 3 meses
                  </ButtonMonthPer>
                  }
                  {per6 ? 
                  <ButtonMonthPerSelect type="button" value="6" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 6 meses
                  </ButtonMonthPerSelect>
                  :  
                  <ButtonMonthPer ButtonMonth  value="6" type="button" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 6 meses
                  </ButtonMonthPer>
                  }
                  {per9 ? 
                  <ButtonMonthPerSelect type="button" value="9" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 9 meses
                  </ButtonMonthPerSelect>
                  :  
                  <ButtonMonthPer ButtonMonth  value="9" type="button" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 9 meses
                  </ButtonMonthPer>
                  }
                  {per12 ? 
                  <ButtonMonthPerSelect type="button" value="12" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 12 meses
                  </ButtonMonthPerSelect>
                  :  
                  <ButtonMonthPer ButtonMonth  value="12" type="button" onClick={e => ChangeMonthPer(e.target.value)}>
                    Últimos 12 meses
                  </ButtonMonthPer>
                  }
                </div>
                <div>
                  {/* <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                    {isSelectedYearAnt ?
                    <ButtonMonthSelect  type="button" value={setAnoAnt} onClick={e => handleSelectYear(1)}>{setAnoAnt}</ButtonMonthSelect>
                    :
                    <ButtonMonth  type="button" value={setAnoAnt} onClick={e => handleSelectYear(1)}>{setAnoAnt}</ButtonMonth>
                    }
                    {isSelectedYearAtual ?
                    <ButtonMonthSelect  type="button" value={setAnoAtual} onClick={e => handleSelectYear(2)}>{setAnoAtual}</ButtonMonthSelect>
                    :
                    <ButtonMonth  type="button" value={setAnoAtual} onClick={e => handleSelectYear(2)}>{setAnoAtual}</ButtonMonth>
                    }                
                  </div> */}
                  {monthJan ? 
                  <ButtonMonthSelect type="button" value="JAN" onClick={e => ChangeMonth(e.target.value)}>
                    JAN
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth  value="JAN" type="button" onClick={e => ChangeMonth(e.target.value)}>
                    JAN
                  </ButtonMonth>
                  }
                  
                  {monthFev ? 
                  <ButtonMonthSelect type="button" value="FEV" onClick={e => ChangeMonth(e.target.value)}>
                    FEV
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="FEV" onClick={e => ChangeMonth(e.target.value)}>
                    FEV
                  </ButtonMonth>
                  }
                  {monthMar ? 
                  <ButtonMonthSelect type="button" value="MAR" onClick={e => ChangeMonth(e.target.value)}>
                    MAR
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="MAR" onClick={e => ChangeMonth(e.target.value)}>
                    MAR
                  </ButtonMonth>
                  }
                  {monthAbr ? 
                  <ButtonMonthSelect type="button" value="ABR" onClick={e => ChangeMonth(e.target.value)}>
                    ABR
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="ABR" onClick={e => ChangeMonth(e.target.value)}>
                    ABR
                  </ButtonMonth>
                  }
                  {monthMai ? 
                  <ButtonMonthSelect type="button" value="MAI" onClick={e => ChangeMonth(e.target.value)}>
                    MAI
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="MAI" onClick={e => ChangeMonth(e.target.value)}>
                    MAI
                  </ButtonMonth>
                  }
                  {monthJun ? 
                  <ButtonMonthSelect type="button" value="JUN" onClick={e => ChangeMonth(e.target.value)}>
                    JUN
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="JUN" onClick={e => ChangeMonth(e.target.value)}>
                    JUN
                  </ButtonMonth>
                  }
                  {monthJul ? 
                  <ButtonMonthSelect type="button" value="JUL" onClick={e => ChangeMonth(e.target.value)}>
                    JUL
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="JUL" onClick={e => ChangeMonth(e.target.value)}>
                    JUL
                  </ButtonMonth>
                  }
                  {monthAgo ? 
                  <ButtonMonthSelect type="button" value="AGO" onClick={e => ChangeMonth(e.target.value)}>
                    AGO
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="AGO" onClick={e => ChangeMonth(e.target.value)}>
                    AGO
                  </ButtonMonth>
                  }
                  {monthSet ? 
                  <ButtonMonthSelect type="button" value="SET" onClick={e => ChangeMonth(e.target.value)}>
                    SET
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="SET" onClick={e => ChangeMonth(e.target.value)}>
                    SET
                  </ButtonMonth>
                  }
                  {monthOut ? 
                  <ButtonMonthSelect type="button" value="OUT" onClick={e => ChangeMonth(e.target.value)}>
                    OUT
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="OUT" onClick={e => ChangeMonth(e.target.value)}>
                    OUT
                  </ButtonMonth>
                  }
                  {monthNov ? 
                  <ButtonMonthSelect type="button" value="NOV" onClick={e => ChangeMonth(e.target.value)}>
                    NOV
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="NOV" onClick={e => ChangeMonth(e.target.value)}>
                    NOV
                  </ButtonMonth>
                  }
                  {/* {monthDez ? 
                  <ButtonMonthSelect type="button" value="DEZ" onClick={e => ChangeMonth(e.target.value)}>
                    DEZ
                  </ButtonMonthSelect>
                  :  
                  <ButtonMonth ButtonMonth type="button" value="DEZ" onClick={e => ChangeMonth(e.target.value)}>
                    DEZ
                  </ButtonMonth>
                  } */}
                </div>
                
              </Periodo>
              <div>
                <div style={{display: 'flex'}}>
                <InputNCM placeholder="NCM" onChange={e => setNCM(e.target.value)} style={{width:'6rem'}}/>
                <Select  onChange={e => setIncoterm(e.target.value)}>
                    <option value="0">Incoterm</option>
                    <option value="CFR">CFR</option>
                    <option value="CIF">CIF</option>
                    <option value="CIP">CIP</option>
                    <option value="CPT">CPT</option>
                    <option value="DAP">DAP</option>
                    <option value="DAT">DAT</option>
                    <option value="DPU">DPU</option>
                    <option value="EXW">EXW</option>
                    <option value="FAS">FAS</option>
                    <option value="FCA">FCA</option>
                    <option value="fob">FOB</option>
                </Select>
                <InputNCM placeholder="País de Origem" onChange={e => setOrigem(e.target.value)}/>
                <InputNCM placeholder="País de Aquisição" onChange={e => setAquisicao(e.target.value)}/>
                <Select onChange={e => setModal(e.target.value)} >
                    <option value="0">MODAL</option>
                    <option value="AÉREA">AEREA</option>
                    <option value="MARíTIMA">MARITIMA</option>
                    <option value="RODOVIÁRIA">RODOVIÁRIA</option>                    
                </Select>
                </div>
                <div style={{display: 'flex'}}>
                <Input placeholder="Importador" onChange={e => setImportador(e.target.value)}/>
                <Input placeholder="Exportador" onChange={e => setExportador(e.target.value)}/>
                <Input placeholder="Notificado" onChange={e => setNotificado(e.target.value)}/>
                </div>
                <div style={{display: 'flex'}}>
                  <InputTwo placeholder="Descrição do Produto" onChange={e => setProduto(e.target.value)}/>
                  <InputTwo placeholder="Unidade Desembaraço" onChange={e => setDesembaraco(e.target.value)}/>
                  <Button type="button" onClick={handleGetFilter}>
                    PESQUISAR
                  </Button>
                </div>
              </div>
             
            </ContainerBox>

           
        </BoxStyle>
        </ContainerHome>
        <Row>
         
            <div style={{display: 'flex', justifyContent: 'center', width:"100%", alignItems: 'center'}}>
            {showLoading ? <> <Aviso>Consultando...  </Aviso>&nbsp;</> : ""}
            {showNoDatas ? <> <Aviso>Nenhum registro localizado!  </Aviso>&nbsp;</> : ""}
            </div>
            </Row>
            <Row>
              {/* <div style={{display: 'flex'}}>  */}
              {             
                
                showButtons ?
                <ContainerBox2>
                  
                    <Button style={{marginRight: '1rem'}} type="button" onClick={handleShowDashboard}>
                    DASHBOARD
                  </Button>
                  <Button type="button" onClick={handleShowDetalhe}>  
                      DETALHES
                  </Button>
                  
                 </ContainerBox2>
                : ""
            }
            {/* </div> */}
        </Row>
        <Row>
        <Container>
          
          {showDashboard ?  
            <Dashboard  resume={dataResume} importer={dataImport} exporter={dataExport} ncm={dataNCM} desembaraco={dataDesembaraco} origemAquisicao={dataOrigemAquisicao} modais={dataModal}/>
          : ""}
          {showDetalhe ?
            <Tablecomex data={dataFilter}/>
          : ""}

          {showClients ? 
            <>
              <div style={{display: 'flex',justifyContent: 'center', alignItems: 'center', width: "100%"}}>
              <ButtonClients type="button" onClick={handleClickOpenModal}>   
                ADICIONAR
              </ButtonClients>
              </div>
              <TableClients />
            </>
          :
          ""
          }         
          <Modal show={getState} title={getTitle} handleClose={hideModal}>
            <div style={{width: "100%"}}>
              <Input placeholder="Nome" onChange={e => setUsuarioNome(e.target.value)} value={getUsuarioNome}/>
              <Input placeholder="Empresa" onChange={e => setUsuarioEmpresa(e.target.value)}/>
              <Input placeholder="Telefone" onChange={e => setUsuarioTelefone(e.target.value)}/>
              <Input placeholder="E-mail" onChange={e => setUsuarioEmail(e.target.value)}/>
              <Input placeholder="Senha" type="password" onChange={e => setUsuarioSenha(e.target.value)}/>
              <div style={{width: "100%", display: 'flex', justifyContent: 'space-between'}}>
              <div>
              <ButtonClients type="button" onClick={handleClickSave}>   
                SALVAR
              </ButtonClients>
              </div>
              <div>
              <ButtonClients type="button" onClick={hideModal}>   
                CANCELAR
              </ButtonClients>              
              </div>
              </div>
            </div>
          </Modal>
        </Container>
        
        
      </Row>
     
  
    </>
  )
  }

export default Home;