import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    /* height: 100%; */
    
`;

export const SubContainer = styled.div`
    width: 350px;
    margin: 0 auto;   
    position:relative;    
    /* padding: 40px 40px 40px 40px; */
    border-radius: 15px;
    transition: all .3s;    
`;

// export const Input = styled.input`
//     height: 2.2rem;
//     width: 20rem;
//     flex: 1 0;
   
    
//     box-shadow: 0 5px 8px 0 rgb(48 54 77 / 50%);  
//     border: 1px solid;
    
//     font-family: 'Poppins', sans-serif;
//     font-weight: 400;
//     display: block;
//     margin-bottom: 1rem;
//     padding: .375rem .75rem;
//     font-size: 1rem;      
//     line-height: 1.5;
//     color: #495057;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #ced4da;
//     border-radius: .25rem;
//     transition: border-color .15s ease-in-out,
//     box-shadow .15s ease-in-out;

//     &:focus{
//         border-color: none;
//     }
// `;

export const Img = styled.img`
    justify-content: center;
    align-items: center;
    height: 20rem;
    width: 20rem;
`;

export const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    background: #666868;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    width: 20rem;
    border-radius: 5px;
    height: 2.6rem;
    border: none;
    outline: none;
    transition: 0.15s;
    text-align: center;
    justify-content: center;
    &:hover {
      opacity: .7;
    }
  `

export const Container2 = styled.div`
    height: 2.6rem;
    width: 20rem;
    /* flex: 1 0; */
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .6rem;
    padding: .375rem .75rem;
       
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;

    &:focus{
        border-color: none;
    }
`;

export const TextInput = styled.input`
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: .75rem;
    background-color: transparent;
    padding-left: 5px;
    border: 0;

`;

export const Icon = styled.div`
    align-items: 'left';
    justify-content: left;
`;