import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types';
import { withStyles, makeStyles, useTheme  } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ReactExport  from 'react-data-export'
import SearchIcon from '@material-ui/icons/Search';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Modal from '../../components/Modal'
import { 
  Container, 
  Label, 
  Column, 
  ExportCsv } from './styles'
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'ANOMES', numeric: false, disablePadding: false, label: 'MÊS/ANO', minWidth: 50, headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'NCM', numeric: false, disablePadding: false, label: 'NCM', minWidth: 50,  headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'INCOTERM', numeric: false, sortable: true, disablePadding: false, label: 'INCOTERM', minWidth: 50,  headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'MODAL', numeric: false, disablePadding: false, label: 'MODAL', minWidth: 50,  headerClassName: 'super-app-theme--header', headerAlign: 'center'  },
  { id: 'DESC_PRODUTO', numeric: false, disablePadding: false, label: 'DESCRIÇÃO DO PRODUTO', minWidth: 350,  headerClassName: 'super-app-theme--header', headerAlign: 'center',
  renderCell: (params =>  (
    <Tooltip title={params.row.DESC_PRODUTO} interactive placement="left-start" >
     <span>{params.row.DESC_PRODUTO}</span>
     </Tooltip>
   )),        
  }, 
  { id: 'EXPORTADOR', numeric: false, disablePadding: false, label: 'EXPORTADOR',  minWidth: 190,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },
  { id: 'IMPORTADOR', numeric: false, disablePadding: false, label: 'IMPORTADOR',  minWidth: 350,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },   
  { id: 'NOTIFICADO', numeric: false, disablePadding: false, label: 'NOTIFICADO',  minWidth: 350,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },   
  { id: 'PAIS_AQUISICAO', numeric: false, disablePadding: false, label: 'PAÍS AQUISIÇÃO',  minWidth: 170,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },     
  { id: 'PAIS_ORIGEM', numeric: false, disablePadding: false, label: 'PAÍS ORIGEM',  minWidth: 150,  headerClassName: 'super-app-theme--header', headerAlign: 'center',  },    
  { id: 'DESEMBARACO', numeric: false, disablePadding: false, label: 'UNIDADE DE DESEMBARAÇO',  minWidth: 250,  headerClassName: 'super-app-theme--header', headerAlign: 'center',  },    
  { id: 'QTD', numeric: false, disablePadding: false, label: 'QTD',  minWidth: 60,   headerClassName: 'super-app-theme--header', headerAlign: 'center' },                                             
  { id: 'SEGURO_UN', numeric: false, disablePadding: false, label: 'SEGURO UN', minWidth: 140,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },      
  { id: 'SEGURO_TOTAL', numeric: false, disablePadding: false, label: 'SEGURO TOTAL', minWidth: 160,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },      
  { id: 'CIF_UN', numeric: false, disablePadding: false, label: 'CIF UN', minWidth: 110,  headerClassName: 'super-app-theme--header', headerAlign: 'center'},   
  { id: 'CIF_TOTAL', numeric: false, disablePadding: false, label: 'CIF TOTAL', minWidth: 130,  headerClassName: 'super-app-theme--header', headerAlign: 'center'},   
  { id: 'FOB_UN', numeric: false, disablePadding: false, label: 'FOB UN', minWidth: 110,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },   
  // { id: 'FOB_TOTAL', numeric: false, disablePadding: false, label: 'FOB TOTAL', minWidth: 130,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },   
  { id: 'FRETE_UN', numeric: false, disablePadding: false, label: 'FRETE UN', minWidth: 130,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },  
  { id: 'FRETE_TOTAL', numeric: false, disablePadding: false, label: 'FRETE TOTAL', minWidth: 150,  headerClassName: 'super-app-theme--header', headerAlign: 'center' },  
  { id: 'PESO_LIQ', numeric: false, disablePadding: false, label: 'PESO LÍQUIDO',  minWidth: 150,   headerClassName: 'super-app-theme--header', headerAlign: 'center' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell colSpan="11" style={{borderRight: '2px solid grey', borderBottom: 'none', fontSize: '.7rem'}}  align="center">DADOS</StyledTableCell>
        <StyledTableCell colSpan="10" align="center">VALORES</StyledTableCell>
      </TableRow>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.headerAlign}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    overflow: 'hidden',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


const StyledTableCell = withStyles(() => ({
  root: {
      borderBottom: "none"
    },
  head: {
      backgroundColor: "#495057",
      color: "#fff",
      fontSize: 12,
      fontWeight: 'bold',
  },
  body: {
      fontSize: 12,
      fontWeight: 'bold',
      color: '#495057'
  }
}))(TableCell);


function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export default function Tablecomex ({data}) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [getImportador, setImportador] = React.useState('');
  const [getCNPJ, setCNPJ] = React.useState('');
  const [getEndereco, setEndereco] = React.useState('');
  const [getStateModal, setStateModal] = React.useState(false);
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    function getUnidade(unidade){
      switch(unidade) {
      case 'UNIDADE' : return  'UN';
      case 'QUILOGRAMA LIQUIDO' : return 'KG';
      case 'CONJUNTO' : return 'CJ';
      case 'PEÇA' : return 'PC';      
      default : return '';
      }
    }
      
    const handleChangePage = (event, newPage) => {  
      setPage(newPage);  
    };  
  
    const handleChangeRowsPerPage = event => {  
      setRowsPerPage(+event.target.value);  
      setPage(0);  
    }; 

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleClickOpenModal = (importardor, cnpj, endereco) => { 
      setImportador(importardor);
      setCNPJ(cnpj)
      setEndereco(endereco);
      setStateModal(true);
    }

    const hideModalModal = () => {
      setStateModal(false);
    }   
  
    const openWebSite = () => {
      if(getImportador !== ''){

        window.open(`https://www.linkedin.com/search/results/all/?keywords=${getImportador}`, "_blank")
      }
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (            
        <Container>
             
             <Column >
             <Paper className={classes.paper}>               
             <ExcelFile  name="Detalhes" element={<ExportCsv>
                  <CloudDownloadOutlinedIcon /><span style={{marginLeft:'.5rem'}}>Exportar</span>
               </ExportCsv>}
             >
               <ExcelSheet data={data} name="Detalhes">                        
                        <ExcelColumn label="MÊS/ANO" value="ANOMES"/>
                        <ExcelColumn label="NCM" value="NCM"/>              
                        <ExcelColumn label="INCOTERM" value="INCOTERM"/>
                        <ExcelColumn label="MODAL" value="MODAL"/> 
                        <ExcelColumn label="DESCRIÇÃO PRODUTO" value="DESC_PRODUTO"/>   
                        <ExcelColumn label="EXPORTADOR" value="EXPORTADOR"/>
                        <ExcelColumn label="IMPORTADOR" value="IMPORTADOR"/>              
                        <ExcelColumn label="IMPORTADOR CNPJ" value="CNPJ"/>           
                        <ExcelColumn label="IMPORTADOR ENDEREÇO" value="ENDERECO"/>           
                        <ExcelColumn label="NOTIFICADO" value="NOTIFICADO"/>
                        <ExcelColumn label="PAÍS AQUISIÇÃO" value="PAIS_AQUISICAO"/> 
                        <ExcelColumn label="PAÍS ORIGEM" value="PAIS_ORIGEM"/>                              
                        <ExcelColumn label="DESEMBARAÇO" value="DESEMBARACO"/>             
                        <ExcelColumn label="PESO LÍQUIDO" value="PESO_LIQ"/>     
                        <ExcelColumn label="QTD" value="QTD"/>
                        <ExcelColumn label="SEGURO UN" value="SEGURO_UN"/>              
                        <ExcelColumn label="SEGURO TOTAL" value="SEGURO_TOTAL"/>
                        <ExcelColumn label="CIF UN" value="CIF_UN"/> 
                        <ExcelColumn label="CIF TOTAL" value="CIF_TOTAL"/>   
                        <ExcelColumn label="FOB UN" value="FOB_UN"/>
                        <ExcelColumn label="FOB TOTAL" value="FOB_TOTAL"/>              
                        <ExcelColumn label="FRETE UN" value="FRETE_UN"/>
                        <ExcelColumn label="FRETE TOTAL" value="FRETE_TOTAL"/>                                           
                    </ExcelSheet>
            </ExcelFile>
               {/* <ExportCsv  onClick={handleExportCSV}>
                  <CloudDownloadOutlinedIcon /><span style={{marginLeft:'.5rem'}}>Exportar</span>
               </ExportCsv> */}
              <TableContainer>
                  <Table   className={classes.table}
                  aria-labelledby="tableTitle"
                  size='small'
                  aria-label="enhanced table">
                  <EnhancedTableHead
                    classes={classes}                    
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                   />
                    <TableBody>
                    {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        return(
                          <TableRow
                          hover
                          key={row.flg_id}
                          style={{height:'30px'}}
                          >
                            <TableCell style={{fontSize: '.7rem'}}> {row.ANOMES}</TableCell>                            
                            <TableCell style={{fontSize: '.7rem'}}>{row.NCM}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.INCOTERM}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.MODAL === "ENTRADA/SAÍDA FICTA" ? "OUTROS" : row.MODAL === "" ? "OUTROS" : row.MODAL}</TableCell>
                            <Tooltip title={row.DESC_PRODUTO}  placement="right-start" >
                            <TableCell 
                               style={{
                                whiteSpace: "nowrap",
                                height: "30px",
                                textOverflow: "ellipsis",
                                marginTop: "5px",
                                fontSize: '.7rem',
                                width: "350px",
                                display: "block",
                                overflow: "hidden"
                              }}
                            >{row.DESC_PRODUTO}</TableCell>
                            </Tooltip>
                            <TableCell 
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: '.7rem',
                              width: "350px",
                              // display: "block",
                              overflow: "hidden"
                            }}
                            >{row.EXPORTADOR}</TableCell>
                            <TableCell 
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: '.7rem',
                              width: "350px",
                              // display: "block",
                              overflow: "hidden"
                            }}
                            >{row.IMPORTADOR}
                            <SearchIcon style={{height: "12", marginTop: "5px", cursor: "pointer"}} onClick={(event) => handleClickOpenModal(row.IMPORTADOR, row.CNPJ, row.ENDERECO)}/></TableCell>
                            <TableCell 
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: '.7rem',
                              width: "250px",
                              // display: "block",
                              overflow: "hidden"
                            }}
                            >{row.NOTIFICADO}</TableCell>
                            <TableCell 
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: '.7rem',
                              width: "150px",
                              // display: "block",
                              overflow: "hidden"
                            }}
                            >{row.PAIS_AQUISICAO}</TableCell>
                            <TableCell 
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontSize: '.7rem',
                              width: "150px",
                              // display: "block",
                              overflow: "hidden"
                            }}
                            >{row.PAIS_ORIGEM}</TableCell>
                            <TableCell 
                            style={{
                              borderRight: '2px solid grey',
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",                              
                              overflow: "hidden",
                              fontSize: '.7rem'}}
                              >{row.DESEMBARACO}</TableCell>
                            <TableCell 
                            style={{                              
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",                              
                              width: "80px",
                              fontSize: '.7rem'}}>{row.QTD} {getUnidade(row.flg_tp_unid_comerc)}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.SEGURO_UN}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.SEGURO_TOTAL}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.CIF_UN}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.CIF_TOTAL}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.FOB_UN}</TableCell>
                            {/* <TableCell style={{fontSize: '.7rem'}}>{row.FOB_TOTAL}</TableCell> */}
                            <TableCell style={{fontSize: '.7rem'}}>{row.FRETE_UN}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.FRETE_TOTAL}</TableCell>
                            <TableCell style={{fontSize: '.7rem'}}>{row.PESO_LIQ}</TableCell>
                          </TableRow>
                        );
                      })
                      }
                       {emptyRows > 0 && (
                <TableRow style={{ height: 35 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
                  </TableBody>
                  <Modal show={getStateModal} title={getImportador} handleClose={hideModalModal}>                    
                      <Label>ENDEREÇO: <span style={{fontSize: '.8rem', fontWeight: 'normal'}}>{getEndereco}</span></Label>
                      <Label>CNPJ: <span style={{fontSize: '.8rem', fontWeight: 'normal'}}>{getCNPJ}</span></Label>
                      <Label href="#" style={{cursor: 'pointer'}} onClick={(event) => openWebSite(getImportador)} rel="noreferrer">
                      <Label style={{float: 'left'}}>LINKED</Label>
                      
                      <LinkedInIcon 
                                  style={{height: '20px',color: 'blue'}}/>
                      </Label>
                  </Modal>
                </Table>
                </TableContainer>
                <TablePagination  
                 rowsPerPageOptions={[10, 20, 30]}  
                 component="div"  
                 count={data.length}  
                 rowsPerPage={rowsPerPage}  
                 page={page} 
                 labelRowsPerPage={"REGISTROS POR PÁGINA"}
                 classes={{
                     root: classes.tablePagination,
                     caption: classes.tablePaginationCaption,
                     selectIcon: classes.tablePaginationSelectIcon,
                     select: classes.tablePaginationSelect,
                     actions: classes.tablePaginationActions,
                   }}
                 onChangePage={handleChangePage}  
                 onChangeRowsPerPage={handleChangeRowsPerPage}  
            />              
            </Paper>   
            
              </Column>                         
      </Container>
    );        
}

