import axios from 'axios';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
const api = axios.create({
    // baseURL: "https://falconcomex.azurewebsites.net/"
    // baseURL: "http://falconapp.eastus.azurecontainer.io/"
    baseURL: "https://falconcom-backend.herokuapp.com/"     
    // baseURL: "http://localhost:80/"    
// 
})

export default api;