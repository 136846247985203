import styled from 'styled-components';

export const ContainerHome = styled.div`
    /* max-width: 1360px; */
    
    /* margin-right: auto;
    margin-left: auto; */
    box-sizing: border-box;
    /* display: flex; */
    &:before,
    &:after{
        content: " "            ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;

export const Row = styled.div`
    width:100%;
    /* height:5rem; */
    display: flex;
    box-sizing: border-box;
    &:before,
    &:after{
        content: " " ;
        display: table;
    }
    &:after{
        clear: both;
    }
`;


export const RowButton = styled.div`
    width:100%;
    height:auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    &:before,
    &:after{
        content: " " ;
        
    }
    &:after{
        clear: both;
    }
`;

export const BoxStyle = styled.div`
    height: 8.5rem;
    width: 100%;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    /* display: flex; */
    margin-bottom: 1rem;
    padding: .375rem .75rem;
    font-size: 1rem;      
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
`;

export const ContainerBox = styled.div`
    margin: .1rem auto;
    justify-content: center;
    display: flex;
`;

export const ContainerBox2 = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`;


export const Periodo = styled.div`
    font-family: 'Poppins', sans-serif;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    padding: .375rem .775rem;
    font-size: .5rem;      
    line-height: 1.5;
    height: 6.5rem;
    width: 25rem;
    margin-right: 1rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;


  option {
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    font-size: .5rem; 
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

export const Input = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .5rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.2rem;
    width: 13.4rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

export const InputNCM = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    /* margin-bottom: .5em; */
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.2rem;
    /* width: 6rem; */
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

export const InputTwo = styled.input`
    font-family: 'Poppins', sans-serif;
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .5rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.2rem;
    width: 15rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;
    ::placeholder {
        color: #495057;
    }
`;

export const Button = styled.button`
    font-family: 'Poppins', sans-serif;
    
    font-weight: 500;
    font-size: .8rem;
    background: #666868;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    width: 10.1rem;
    border-radius: 5px;
    height: 2.2rem;
    border: none;
    outline: none;
    transition: 0.15s;
    text-align: center;
    
    &:hover {
      opacity: .7;
    }
  `

export const ButtonClients = styled.button`
font-family: 'Poppins', sans-serif;

font-weight: 500;
font-size: .8rem;
background: #666868;
color: #fff;
cursor: pointer;
text-transform: uppercase;
width: 5rem;
border-radius: 5px;
height: 1.5rem;
border: none;
outline: none;
transition: 0.15s;
text-align: center;

&:hover {
  opacity: .7;
}
`

export const ButtonMonth = styled.button`
font-family: 'Poppins', sans-serif;
margin-left: .3rem;
margin-top: .2rem;
font-weight: 500;
font-size: .6rem;
background: #666868;
color: #fff;
cursor: pointer;
text-transform: uppercase;
width: 4rem;
border-radius: 5px;
height: 1.2rem;
border: none;
outline: none;
transition: 0.15s;
text-align: center;

&:hover {
  opacity: .7;
}
`

export const ButtonMonthPer = styled.button`
font-family: 'Poppins', sans-serif;
margin-top: .2rem;
font-weight: normal;
font-size: .55rem;
background: #666868;
color: #fff;
cursor: pointer;
text-transform: uppercase;
width: 6rem;
border-radius: 5px;
height: 1.3rem;
border: none;
outline: none;
transition: 0.15s;
text-align: center;

&:hover {
  opacity: .7;
}
`;

export const ButtonMonthPerSelect = styled.button`
font-family: 'Poppins', sans-serif;
margin-top: .2rem;
font-weight: normal;
font-size: .55rem;
background: #666868;
color: #fff;
opacity: .7;
cursor: pointer;
text-transform: uppercase;
width: 6rem;
border-radius: 5px;
height: 1.3rem;
border: none;
outline: none;
transition: 0.15s;
text-align: center;
`

export const ButtonMonthSelect = styled.button`
font-family: 'Poppins', sans-serif;
margin-left: .3rem;
margin-top: .2rem;
font-weight: 500;
font-size: .55rem;
background: #666868;
color: #fff;
opacity: .7;
cursor: pointer;
text-transform: uppercase;
width: 4rem;
border-radius: 5px;
height: 1.2rem;
border: none;
outline: none;
transition: 0.15s;
text-align: center;
`


export const ImgLogo = styled.img`  
    /* margin-top: -4rem; */
    /* float: left; */
    height: 5rem;
    width: 9rem;
`;

export const Img = styled.img`
    margin-top: .2rem;
    height: 1rem;
`;

export const Data = styled.div`
`;


export const Select = styled.select`
    box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
    border: 1px solid;
    
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    display: flex;
    margin-bottom: .5rem;
    margin-right: .5rem;
    padding: .375rem .75rem;
    font-size: .7rem;      
    line-height: 1.5;
    height: 2.2rem;
    width: 6.2rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;

    option {
        font-family: 'Poppins', sans-serif;
        box-shadow: 0 1px 5px 0 rgb(48 54 77 / 40%);  
        border: 1px solid;
        
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        display: flex;
        margin-bottom: 1rem;
        margin-right: 1rem;
        padding: .375rem .75rem;
        font-size: .7rem;      
        line-height: 1.5;
        height: 2.2rem;
        width: 30rem;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: transparent;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,
        box-shadow .15s ease-in-out;
    }
`;

export const Aviso = styled.div`
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: .7rem;      
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
`;


function getWidthGrid(value){
    if (!value) return;

    let width = value / 12 * 100;
    return `width ${width}%;`;
}


export const Column = styled.div`
    float: left;
    padding: .25rem;
    min-height: 1px;
    box-sizing: border-box;

    @media only screen and (max-width: 768px) {
        ${({mobile}) => mobile && getWidthGrid(mobile)}
    }

    @media only screen and (min-width: 768px) {
        ${({tablet}) => tablet && getWidthGrid(tablet)}
    }

    @media only screen and (min-width: 1000px) {
        ${({desktop}) => desktop && getWidthGrid(desktop)}
    }
`;

