import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/scss/Main.scss"
import Root from './components/Root';




ReactDOM.render(
  <Root/>,
  document.getElementById('root')
);